import { connect, useDispatch } from 'react-redux';
import toJS from '../../../../hoc/toJS';
import select from '../../../../utils/select';
import {
	Grid,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	FormLabel,
	FormHelperText
} from '@material-ui/core';
import {
	createTimeResearchDetail,
	filterYear
} from '../../../../reducer/timeResearchInformation/action';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { notification } from '../../../../reducer/home/action';

const validationSchema = Yup.object({
	numOfTime: Yup.number()
		.typeError('Số giờ phải là một số')
		.min(1, 'Số giờ phải lớn hơn 0')
		.required('Không được bỏ trống số giờ'),
	description: Yup.string().required('Không được bỏ trống mô tả')
});

function ResearchTimeYearAddOneModal(props) {
	const { adding, setAdding, handleReload, initialValues } = props;
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			dispatch(createTimeResearchDetail(values)).finally(() => {
				setAdding(false);
				formik.resetForm();
				handleReload();
			});
		}
	});
	const handleClose = () => {
		setAdding(false);
		formik.resetForm();
	};
	const hasError = Boolean(
		(formik.touched.description && formik.errors.description) ||
			(formik.touched.numOfTime && formik.errors.numOfTime) ||
			JSON.stringify(formik.values) === JSON.stringify(initialValues)
	);

	return (
		<Dialog fullWidth keepMounted maxWidth="sm" open={adding} onClose={handleClose}>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Thêm nhiệm vụ tính giờ nghiên cứu </DialogTitle>
				<DialogContent style={{ overflow: 'hidden' }}>
					<Grid container spacing={2} style={{ marginBottom: 10 }}>
						<Grid item xs={12}>
							<FormControl
								fullWidth
								error={formik.touched.description && Boolean(formik.errors.description)}
							>
								<FormLabel style={{ color: 'black', marginBottom: '5px' }}>
									Mô tả nhiệm vụ
								</FormLabel>
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									multiline
									name="description"
									value={formik.values.description}
									onChange={(event) =>
										formik.setValues({
											...formik.values,
											description: event.target.value
										})
									}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.description && formik.errors.description && (
									<FormHelperText sx={{ color: 'red', fontStyle: 'italic' }}>
										{formik.errors.description}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl
								fullWidth
								error={formik.touched.numOfTime && Boolean(formik.errors.numOfTime)}
							>
								<FormLabel style={{ color: 'black', marginBottom: '5px' }}>
									Số giờ nghiên cứu (h)
								</FormLabel>
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									name="numOfTime"
									value={formik.values.numOfTime}
									onChange={(event) =>
										formik.setValues({
											...formik.values,
											numOfTime: parseInt(event.target.value)
										})
									}
									onBlur={formik.handleBlur}
								/>
								{formik.touched.numOfTime && formik.errors.numOfTime && (
									<FormHelperText sx={{ color: 'red', fontStyle: 'italic' }}>
										{formik.errors.numOfTime}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions style={{ padding: '0 24px 8px 24px' }}>
					<div style={{ float: 'right' }}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleClose}
							style={{ marginRight: 4 }}
						>
							Huỷ
						</Button>
						<Button variant="contained" color="primary" type="submit" disabled={hasError}>
							Xác nhận
						</Button>
					</div>
				</DialogActions>
			</form>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list')
});

const mapDispatchToProps = (dispatch) => ({
	filterYear: (year) => dispatch(filterYear(year))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ResearchTimeYearAddOneModal));
