import { Autocomplete } from '@material-ui/lab';
import { Person, SaveAlt, School } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	TextField,
	Tooltip
} from '@material-ui/core';

import select from '../../../../../utils/select';
import PersonalTab from './PersonalTab';
import DepartmentTab from './DepartmentTab';

import { apiCall } from '../../../../../utils/api';
import { API_URLS } from '../../../../../constants/api';
import {
	filterTimeResearch,
	getTimeResearchByProfileIdV2
} from '../../../../../reducer/profile/action';
import { converted, converteDepartment, exportDepartment, exportPersonal } from '../../utils';

function ListTable2022(props) {
	const dispatch = useDispatch();
	const list = window.location.href.split('/');
	const lastValueList = list[list.length - 1].split('=');
	const selectedProfileId =
		lastValueList.length > 1 ? lastValueList[lastValueList.length - 1] : null;

	const [dmTimeReseach, setDmTimeReseach] = useState(0);
	const [isPersonalTab, setIsPersonalTab] = useState(true);
	const [isFetchingNorm, setIsFetchingNorm] = useState(true);
	const [chosenDepartmentId, setChosenDepartmentId] = useState(null);

	const { t___Research, profileDetail, role, isFetching, departments, listT___Research } =
		useSelector((state) => ({
			role: select(state, 'userReducer', 'role'),
			isFetching: select(state, 'profileReducer', 'isFetching'),
			departments: select(state, 'departmentReducer', 'departments'),
			t___Research: select(state, 'profileReducer', 'timeResearch'),
			profileDetail: select(state, 'profileReducer', 'detail'),
			listT___Research: select(state, 'profileReducer', 'listTimeResearch')
		}));

	const timeResearch = useMemo(() => {
		return t___Research && departments && converted(t___Research, departments);
	}, [t___Research, departments]);

	const listTimeResearch = useMemo(() => {
		return listT___Research && departments && converteDepartment(listT___Research);
	}, [listT___Research, departments]);

	const listDepartmentInCharge = useMemo(() => {
		return departments?.filter(
			(department) =>
				role === 'sudo-admin' ||
				!!profileDetail?.departmentProfile?.find(
					(e) => department.id === e.departmentId && e.roleInDepartment.toLowerCase() === 'leader'
				)
		);
	}, [departments, profileDetail, role]);

	const reload = (id) => {
		if (isPersonalTab) {
			dispatch(getTimeResearchByProfileIdV2(id, props.year));
		} else {
			dispatch(filterTimeResearch({ departmentId: id, year: props.year }));
		}
	};

	useEffect(() => {
		if (isPersonalTab && profileDetail?.id) reload(selectedProfileId || profileDetail?.id);
	}, [isPersonalTab, profileDetail, props.year]);

	useEffect(() => {
		const getDmTimeReseach = async () => {
			setIsFetchingNorm(true);
			const year = props.year;
			let api = API_URLS.HRM.getDmTimeReseach();
			api.endPoint = api.endPoint.replace('{year}', year);

			const { response, error } = await apiCall(api);
			if (!error && response.status === 200) {
				const listDm = response.data.data;
				const dm = listDm.find((dm) => dm.email === t___Research.email);
				setDmTimeReseach(dm?.researchNorm || 0);
			}
			setIsFetchingNorm(false);
		};
		if (t___Research) getDmTimeReseach();
	}, [t___Research]);

	return (
		<>
			<Grid item xs={3}></Grid>
			<Grid item xs={3}>
				<Box display="flex" alignItems="center" style={{ float: 'right' }}>
					{listDepartmentInCharge.length > 0 && (
						<>
							{!isPersonalTab && (
								<form>
									<Autocomplete
										disableClearable
										options={listDepartmentInCharge}
										disabled={role !== 'sudo-admin' && listDepartmentInCharge === 1}
										getOptionLabel={(option) => option.name}
										onChange={(_, val) => {
											setChosenDepartmentId(val?.id);
											reload(val?.id);
										}}
										// value={departments?.find((e) => e.id === chosenDepartmentId)}
										getOptionSelected={(option, value) => option.id === value?.id}
										renderInput={(params) => (
											<TextField
												{...params}
												size="small"
												label="Khoa"
												variant="outlined"
												placeholder="Cả trường"
											/>
										)}
										style={{ width: 260, marginRight: 16 }}
									/>
								</form>
							)}
							<Tooltip title="Cá nhân">
								<IconButton
									size="small"
									onClick={() => setIsPersonalTab(true)}
									color={isPersonalTab ? 'secondary' : 'default'}
								>
									<Person />
								</IconButton>
							</Tooltip>
							<Tooltip title="Đơn vị">
								<IconButton
									size="small"
									onClick={() => setIsPersonalTab(false)}
									color={!isPersonalTab ? 'secondary' : 'default'}
									style={{ margin: '0 8px' }}
								>
									<School />
								</IconButton>
							</Tooltip>
						</>
					)}
					<Button
						color="primary"
						variant="contained"
						startIcon={
							isFetchingNorm || isFetching ? (
								<CircularProgress style={{ width: 16, height: 16 }} />
							) : (
								<SaveAlt />
							)
						}
						disabled={isPersonalTab && (isFetchingNorm || isFetching)}
						onClick={() => {
							if (isPersonalTab) {
								exportPersonal(timeResearch, dmTimeReseach, profileDetail, props.year);
							} else {
								const selectedDepartment = departments.find((d) => d.id === chosenDepartmentId);
								exportDepartment(listTimeResearch, selectedDepartment.name, props.year);
							}
						}}
						style={{ height: 36.5 }}
					>
						Export
					</Button>
				</Box>
			</Grid>
			<Grid item xs={12}>
				{isPersonalTab ? (
					<PersonalTab reload={reload} timeResearch={timeResearch} />
				) : (
					<DepartmentTab reload={reload} listTimeResearch={listTimeResearch} />
				)}
			</Grid>
		</>
	);
}

export default ListTable2022;
