import dayjs from 'dayjs';
import { Box, Button, IconButton, Typography, Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Close, DoubleArrow, Save } from '@material-ui/icons';

import { apiCall } from '../../../../utils/api';
import { API_URLS } from '../../../../constants/api';
import { valueFormat } from '../../../../utils/valueFormat';
import { KPTextform } from '../../../../components/KPTComponents';
import { notification } from '../../../../reducer/home/action';
import { connect } from 'react-redux';
import toJS from '../../../../hoc/toJS';

function SettingForm(props) {
	const [values, setValues] = useState(null);

	const textForm = [
		{
			key: 'year',
			label: 'Năm',
			xs: 12,
			disabled: true,
			value: `${props.year} - ${props.year + 1}`
		},
		{
			key: 'divider',
			xs: 12,
			type: 'render',
			render: () => <Divider />
		},
		{
			xs: 3,
			key: 'text1',
			type: 'render',
			render: () => (
				<Typography variant="body1" style={{ marginTop: 25.5, lineHeight: '35px' }}>
					<b>Đăng ký hồ sơ:</b>
				</Typography>
			)
		},
		{ xs: 4, key: 'date1Start', type: 'date', label: 'Ngày bắt đầu', required: true },
		{
			xs: 1,
			key: 'icon1',
			type: 'render',
			render: () => (
				<Box width="100%" style={{ marginTop: 36 }}>
					<DoubleArrow color="disabled" style={{ margin: '0 calc((100% - 24px) / 2)' }} />
				</Box>
			)
		},
		{ xs: 4, key: 'date1End', type: 'date', label: 'Ngày kết thúc', required: true },
		{
			xs: 3,
			key: 'text2',
			type: 'render',
			render: () => (
				<Typography variant="body1" style={{ marginTop: 25.5, lineHeight: '35px' }}>
					<b>Báo cáo tiến độ:</b>
				</Typography>
			)
		},
		{ xs: 4, key: 'date2Start', type: 'date', label: 'Ngày bắt đầu', required: true },
		{
			xs: 1,
			key: 'icon2',
			type: 'render',
			render: () => (
				<Box width="100%" style={{ marginTop: 36 }}>
					<DoubleArrow color="disabled" style={{ margin: '0 calc((100% - 24px) / 2)' }} />
				</Box>
			)
		},
		{ xs: 4, key: 'date2End', type: 'date', label: 'Ngày kết thúc', required: true },
		{
			xs: 3,
			key: 'text3',
			type: 'render',
			render: () => (
				<Typography variant="body1" style={{ marginTop: 25.5, lineHeight: '35px' }}>
					<b>Nghiệm thu:</b>
				</Typography>
			)
		},
		{ xs: 4, key: 'date3Start', type: 'date', label: 'Ngày bắt đầu', required: true },
		{
			xs: 1,
			key: 'icon3',
			type: 'render',
			render: () => (
				<Box width="100%" style={{ marginTop: 36 }}>
					<DoubleArrow color="disabled" style={{ margin: '0 calc((100% - 24px) / 2)' }} />
				</Box>
			)
		},
		{ xs: 4, key: 'date3End', type: 'date', label: 'Ngày kết thúc', required: true }
	];

	const getData = async () => {
		const api = API_URLS.YEARLY_DUE_DATE.filterYearlyDueDate();
		const { response, error } = await apiCall({
			...api,
			payload: {
				type: 'internal',
				schoolYear: props.year
			}
		});
		if (!error && response.status === 200 && response.data.success) {
			const res = response.data.data[0];
			setValues({
				...res,
				date1Start: dayjs(res?.regStartTime).format('DD/MM/YYYY'),
				date2Start: dayjs(res?.reportStartTime).format('DD/MM/YYYY'),
				date3Start: dayjs(res?.acceptStartTime).format('DD/MM/YYYY'),
				date1End: dayjs(res?.regEndTime).format('DD/MM/YYYY'),
				date2End: dayjs(res?.reportEndTime).format('DD/MM/YYYY'),
				date3End: dayjs(res?.acceptEndTime).format('DD/MM/YYYY')
			});
		}
	};

	const updateData = async (payload) => {
		props.handleClose();
		const api = API_URLS.YEARLY_DUE_DATE.updateYearlyDueDate();
		await apiCall({ ...api, payload })
			.then(() => {
				props.notification({
					type: 'success',
					message: 'Cập nhật thành công!'
				});
			})
			.catch(() => {
				props.notification({
					type: 'error',
					message: 'Cập nhật thất bại!'
				});
			});
	};

	useEffect(() => getData(), [props.year]);

	const handleSubmit = () => {
		var date1Start = valueFormat.stringToDate(values?.date1Start);
		var date2Start = valueFormat.stringToDate(values?.date2Start);
		var date3Start = valueFormat.stringToDate(values?.date3Start);
		var date1End = valueFormat.stringToDate(values?.date1End);
		var date2End = valueFormat.stringToDate(values?.date2End);
		var date3End = valueFormat.stringToDate(values?.date3End);

		updateData({
			...values,
			schoolYear: props.year,
			regStartTime: date1Start,
			reportStartTime: date2Start,
			acceptStartTime: date3Start,
			regEndTime: date1End,
			reportEndTime: date2End,
			acceptEndTime: date3End,
			type: 'internal'
		});
	};

	return (
		<KPTextform
			id={props.id}
			textForm={textForm}
			values={values}
			setValues={setValues}
			handleSubmit={handleSubmit}
		/>
	);
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
	notification: (payload) => dispatch(notification(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SettingForm));
