import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
	Grid,
	Button,
	Dialog,
	Select,
	Divider,
	TextField,
	DialogTitle,
	DialogContent,
	DialogActions,
	InputLabel,
	Typography,
	FormControl,
	FormHelperText,
	MenuItem
} from '@material-ui/core';
import dayjs from 'dayjs';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import FileUploadModal from '../../File/FileUploadModal';
import Other from '../ScientificResearchInsertForm/Other';
import Chapter from '../ScientificResearchInsertForm/Chapter';
import Conference from '../ScientificResearchInsertForm/Conference';
import WorkingPaper from '../ScientificResearchInsertForm/WorkingPaper';
import JournalDomestic from '../ScientificResearchInsertForm/JournalDomestic';
import ConferenceDomestic from '../ScientificResearchInsertForm/ConferenceDomestic';
import JournalInternational from '../ScientificResearchInsertForm/JournalInternational';
import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';
import { uploadFile } from '../../../reducer/file/action';
import { resetDetail } from '../../../reducer/journal/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import { insertScientificResearchOther } from '../../../reducer/scientificResearchOther/action';
import { getAllEmploymentContractByProfileId } from '../../../reducer/employmentContract/action';
import {
	insertScientificResearch,
	updateScientificResearch
} from '../../../reducer/scientificResearch/action';
import { getTimeResearchInformation } from '../../../reducer/timeResearchInformation/action';
import { notification } from '../../../reducer/home/action';
import ScientificResearchTimeTable from '../ScientificResearchTimeTable/ScientificResearchTimeTable';

export const TIME_RESEARCH_DETAIL = {
	journalInternational: [
		'Bài báo đăng trên tạp chí trong danh mục ISI (Không sử dụng để nghiệm thu KPI hoặc nhận thưởng KPI nghiên cứu).',
		'Bài báo đăng trên tạp chí trong danh mục Scopus (Không sử dụng để nghiệm thu KPI hoặc nhận thưởng KPI nghiên cứu).',
		'Bài báo khoa học đăng trên các tạp chí quốc tế khác.'
	],
	journalDomestic: [
		'Bài báo đăng trên các tạp chí trong nước (trong danh mục của Hội đồng Giáo sư Nhà nước).',
		'Bài báo đăng trên các tạp chí khoa học trong nước khác.'
	],
	conference: [
		'Kỷ yếu Hội nghị quốc tế (ISSN).',
		'Báo cáo tại Hội nghị quốc tế (không đăng báo hoặc kỷ yếu).'
	],
	conferenceDomestic: [
		'Kỷ yếu Hội nghị trong nước (ISSN).',
		'Báo cáo tại Hội nghị trong nước (không đăng báo hoặc kỷ yếu).',
		'Tham luận tại Hội nghị, tọa đàm cấp Trường.'
	],
	chapter: [],
	workingPaper: [],
	other: []
};

export const scientificTypeSelections = [
	{
		type: 'scientificResearchJournal',
		title: 'Tạp chí quốc tế'
	},
	{
		type: 'scientificResearchJournalDomestic',
		title: 'Tạp chí trong nước'
	},
	{
		type: 'scientificResearchConference',
		title: 'Hội thảo quốc tế'
	},
	{
		type: 'scientificResearchConferenceDomestic',
		title: 'Hội thảo trong nước'
	},
	{
		type: 'chapterJournal',
		title: 'Chương sách'
	},
	{
		type: 'working',
		title: 'Working Paper'
	},
	{
		type: 'scientificResearchOther',
		title: 'Tạp chí khác'
	}
];

const TYPE_FILTER_MAPPING = {
	scientificResearchJournal: 'journalInternational',
	scientificResearchJournalDomestic: 'journalDomestic',
	scientificResearchConference: 'conference',
	scientificResearchConferenceDomestic: 'conferenceDomestic',
	chapterJournal: 'chapter',
	working: 'workingPaper',
	scientificResearchOther: 'other'
};

function SolutionInsertModal(props) {
	const { inserting, setInserting, getTimeResearchInformation } = props;

	const [detail, setDetail] = useState(null);
	const [values, setValues] = useState({ purposeId: 3 });
	const [profileList, setProfileList] = useState([]);
	const [helperText, setHelperText] = useState(false);
	const [timeResearchYear, setTimeResearchYear] = useState(new Date().getFullYear());
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [files, setFiles] = useState([]);
	const dateFormat = (value) => (value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ') : null);
	const dispatch = useDispatch();
	const [filter, setFilter] = useState(props.filter);

	// Tự gán bản thân vào danh sách tác giả
	useEffect(() => {
		if (props.profile?.id && profileList.length === 0) {
			setProfileList([
				{
					name: props.profile.name,
					profileId: props.profile.id,
					roleInPaper: 'main'
				}
			]);
		}
	}, [props.inserting, props.profile?.id]);

	// Đổi filter thì reset giá trị
	useEffect(() => {
		setDetail(null);
		setValues({ purposeId: 3 });
		setProfileList([]);
	}, [props.filter]);

	// Bỏ KPI bỏ luôn contract
	useEffect(() => {
		if (!values?.profileIdForKpi) handleChangeForm('contractIdForKpi', null);
	}, [values?.profileIdForKpi]);

	useEffect(() => {
		if (
			props.timeResearchInformationList.slice(0, 1)?.shift()?.year !== timeResearchYear &&
			!props.isFetchingTimeResearchInformation
		)
			getTimeResearchInformation(0, timeResearchYear);
	}, [timeResearchYear]);

	const handleChangeForm = (name, value) => {
		setValues((current) => ({ ...current, [name]: value }));

		// handle to set timeResearchYear when inputting acceptedDay field
		if (name === 'acceptedDay' && value && values.purposeId && values.purposeId === 5) {
			autoSetTimeResearchYear(value);
		}

		// handle to set timeResearchYear when inputting acceptedMonth field
		if (name === 'acceptedMonth' && values.purposeId && values.purposeId === 5) {
			if (values.acceptedDay) {
				let acceptedYear = values.acceptedDay.getFullYear();
				if (value) {
					const acceptedDate = new Date(acceptedYear, value - 1, 1);
					acceptedYear = findYear(acceptedDate, acceptedYear);
				}
				const descYear = props.timeResearchInformationList
					.sort((a, b) => b.year - a.year)
					.map((e) => e.year);
				const existedYear = descYear.some((e) => e === acceptedYear);
				setTimeResearchYear(existedYear ? acceptedYear : descYear[0]);
			}
		}

		if (name === 'purposeId') {
			setValues({
				...values,
				purposeId: value,
				profileIdForKpi: null,
				contractIdForKpi: null,
				profileIdForReward: null,
				profileIdForHour30: null,
				profileIdForHour100: null
			});
			// handle to set timeResearchYear when selecting purpose is time research caculation
			if (value === 5) {
				if (values.acceptedDay) {
					autoSetTimeResearchYear(values.acceptedDay);
					return;
				}
				assignTimeResearchYear();
			}
		}
	};

	const autoSetTimeResearchYear = (acceptedDay) => {
		let acceptedYear = acceptedDay.getFullYear();
		if (values.acceptedMonth) {
			const acceptedDate = new Date(acceptedYear, values.acceptedMonth - 1, 1);
			acceptedYear = findYear(acceptedDate, acceptedYear);
		}
		const descYear = props.timeResearchInformationList
			.sort((a, b) => b.year - a.year)
			.map((e) => e.year);
		const existedYear = descYear.some((e) => e === acceptedYear);
		setTimeResearchYear(existedYear ? acceptedYear : descYear[0]);
	};

	const findYear = (checkingValue, givenYear) => {
		const startDate = new Date(givenYear, 6, 1); // define 1/7/(given year)
		const endDate = new Date(givenYear + 1, 5, 30, 23, 59, 59); // define 30/6/(given year + 1)
		if (checkingValue < startDate) return givenYear - 1;
		if (checkingValue > endDate) return givenYear + 1;
		return givenYear;
	};

	const handleChangeProfileList = (index, name, value) => {
		setProfileList((temp) => {
			const tempElm = profileList[index];
			temp.splice(index, 1, { ...tempElm, [name]: value });
			return [...temp];
		});
	};

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInPaper: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		if (profileList[index].profileId === values?.profileIdForKpi)
			handleChangeForm('profileIdForKpi', null);

		const temp = profileList.filter((e, id) => id !== index);
		setProfileList([...temp]);
	};

	const handleClose = () => {
		setInserting(false);
		props.resetDetail();
	};

	const handleSubmit = (e) => {
		if (values.purposeId === 1) {
			if (!values?.profileIdForKpi) {
				if (!detail) {
					dispatch(
						notification({
							type: 'warning',
							message: 'Phải chọn 1 người tính KPI cho bài báo!'
						})
					);
					return;
				}
			}

			if (!detail) {
				dispatch(
					notification({
						type: 'warning',
						message: 'Phải chọn năm tính KPI!'
					})
				);
				return;
			}
		}
		props.resetDetail();
		if (profileList.findIndex((e) => e.profileId === null) < 0) {
			setInserting(false);
			const d = new Date();
			const tempListAuthor = values?.listAuthor?.split(',').map((author) => author.trimStart());
			if (props.filter !== 'other') {
				if (props.filter === 'conference') {
					props
						.insertScientificResearch({
							...values,
							dashboardYear: new Date(
								dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD'))
							).getFullYear(),
							listAuthor: tempListAuthor,
							journalId: values?.journalId,
							journalDomesticId: values?.journalDomesticId,
							conferenceId:
								props.filter === 'conference'
									? values.conferenceId
									: props.filter === 'conferenceDomestic'
										? values.conferenceDomesticId
										: null,
							conferenceDomesticId: null,
							profileId: props.profile?.id,
							scientificResearchProfile: profileList,
							type: props.type,
							acceptedDay: dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD')),
							submissionDay: dateFormat(values?.submissionDay || moment().format('YYYY-MM-DD')),
							accepted: props.type === 'working' ? true : false,
							page: typeof values.page === 'number' ? values.page.toString() : values.page
						})
						.then((response) => {
							if (response.success && files.length !== 0) {
								props
									.uploadFile(response.data.scientificResearch.id, 'scientificResearch', files)
									.then((responseUpload) => {
										if (responseUpload.success)
											props.updateScientificResearch(response.data.scientificResearch.id, {
												...response.data.scientificResearch,
												fileUrl: responseUpload.data
											});
									})
									.catch();
							}
							props.statisticUnaccepted({
								departmentId: 0,
								profileId: props.profile?.id,
								other: props.other
							});
							if (props.role === 'sudo-admin')
								props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
						})
						.catch();
				}
				if (props.filter === 'conferenceDomestic') {
					props
						.insertScientificResearch({
							...values,
							dashboardYear: new Date(
								dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD'))
							).getFullYear(),
							listAuthor: tempListAuthor,
							journalId: values?.journalId,
							journalDomesticId: values?.journalDomesticId,
							conferenceId:
								props.filter === 'conference'
									? values.conferenceId
									: props.filter === 'conferenceDomestic'
										? values.conferenceDomesticId
										: null,
							conferenceDomesticId: null,
							profileId: props.profile?.id,
							scientificResearchProfile: profileList,
							type: props.type,
							acceptedDay: dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD')),
							submissionDay: dateFormat(values?.submissionDay || moment().format('YYYY-MM-DD')),
							accepted: props.type === 'working' ? true : false,
							page: typeof values.page === 'number' ? values.page.toString() : values.page
						})
						.then((response) => {
							if (response.success && files.length !== 0) {
								props
									.uploadFile(response.data.scientificResearch.id, 'scientificResearch', files)
									.then((responseUpload) => {
										if (responseUpload.success)
											props.updateScientificResearch(response.data.scientificResearch.id, {
												...response.data.scientificResearch,
												fileUrl: responseUpload.data
											});
									})
									.catch();
							}
							props.statisticUnaccepted({
								departmentId: 0,
								profileId: props.profile?.id,
								other: props.other
							});
							if (props.role === 'sudo-admin')
								props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
						})
						.catch();
				}
				if (props.filter !== 'conferenceDomestic' && props.filter !== 'conference') {
					props
						.insertScientificResearch({
							...values,
							dashboardYear: new Date(
								dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD'))
							).getFullYear(),
							listAuthor: tempListAuthor,
							journalId: values?.journalId,
							journalDomesticId: values?.journalDomesticId,
							conferenceId:
								props.filter === 'conference'
									? values.conferenceId
									: props.filter === 'conferenceDomestic'
										? values.conferenceDomesticId
										: null,
							conferenceDomesticId: null,
							profileId: props.profile?.id,
							scientificResearchProfile: profileList,
							type: props.type,
							acceptedDay: dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD')),
							submissionDay: dateFormat(values?.submissionDay || moment().format('YYYY-MM-DD')),
							accepted: props.type === 'working' ? true : false,
							page: typeof values.page === 'number' ? values.page.toString() : values.page
						})
						.then((response) => {
							if (response.success && files.length !== 0) {
								props
									.uploadFile(response.data.scientificResearch.id, 'scientificResearch', files)
									.then((responseUpload) => {
										if (responseUpload.success)
											props.updateScientificResearch(response.data.scientificResearch.id, {
												...response.data.scientificResearch,
												fileUrl: responseUpload.data
											});
									})
									.catch();
							}
							props.statisticUnaccepted({
								departmentId: 0,
								profileId: props.profile?.id,
								other: props.other
							});
							if (props.role === 'sudo-admin')
								props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
						})
						.catch();
				}
			} else {
				// change scientific other
				props
					.insertScientificResearch({
						...values,
						score: values.score || 0,
						listAuthor: tempListAuthor,
						scientificResearchOtherProfile: profileList,
						acceptedDay: dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD')),
						submissionDay: dateFormat(values?.submissionDay || moment().format('YYYY-MM-DD')),
						dashboardYear: new Date(
							dateFormat(values?.acceptedDay || moment().format('YYYY-MM-DD'))
						).getFullYear(),
						type: 'scientificResearchOther',
						page: typeof values.page === 'number' ? values.page.toString() : values.page
					})
					.then((response) => {
						if (response.success && files.length !== 0) {
							props
								.uploadFile(response.data.id, 'scientificResearchOther', files)
								.then((responseUpload) => {
									if (responseUpload.success)
										props.updateScientificResearch(response.data.scientificResearch.id, {
											...response.data.scientificResearch,
											fileUrl: responseUpload.data
										});
								})
								.catch();
						}
						props.statisticUnaccepted({
							departmentId: 0,
							profileId: props.profile?.id,
							other: props.other
						});
						if (props.role === 'sudo-admin')
							props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
					})
					.catch();
			}

			setValues(null);
			setProfileList([]);
			setHelperText(false);
		} else {
			setHelperText(true);
		}
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);

	const handleUpload = (files) => {
		setFiles(files);
		handleCloseUploadModal();
	};

	const getTypeAsTitle = (type) => {
		switch (type) {
			case 'journalInternational':
				return 'trên tạp chí quốc tế';
			case 'journalDomestic':
				return 'trên tạp chí trong nước';
			case 'conference':
				return 'trên hội thảo quốc tế';
			case 'conferenceDomestic':
				return 'trên hội thảo trong nước';
			case 'chapter':
				return 'là chương sách';
			case 'workingPaper':
				return 'đang thực hiện';
			case 'other':
				return 'trên tạp chí khác';
			default:
				return '';
		}
	};

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	const profileListFromProps = [{ id: -1, name: '' }];
	props.profileList.forEach((e) => profileListFromProps.push(e));

	const timeResearchDetailList = useMemo(() => {
		const timeResearchDetail = TIME_RESEARCH_DETAIL[props.filter];
		var result =
			props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
				?.timeResearchDetail || [];

		result = result.filter((e) => !e.allowedUpdateNum && e.type === 'scientificResearch');
		result = result.filter((e) => {
			return timeResearchDetail.some(
				(el) => el.trim().toLocaleUpperCase() === e.description.trim().toLocaleUpperCase()
			);
		});
		return result;
	}, [props.timeResearchInformationList, timeResearchYear, props.filter]);

	const yearList = [];
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	const propsValues = {
		values: values,
		profileList: profileList,
		setValues: setValues,
		handleInsert: handleInsert,
		handleRemove: handleRemove,
		handleChangeForm: handleChangeForm,
		handleChangeProfileList: handleChangeProfileList
	};

	const needCalKpi = useMemo(() => {
		if (values?.purposeId !== 1) {
			return false;
		}
		return values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0;
	}, [values]);

	const needCalHour = useMemo(() => {
		return values?.purposeId === 5;
	}, [values]);

	const isFullSize = useMemo(() => {
		return needCalKpi || needCalHour;
	}, [needCalKpi, needCalHour]);

	const selectedTimeResearchDetail = useMemo(
		() => timeResearchDetailList?.find((e) => e.code === values?.timeResearchDetailCode),
		[timeResearchDetailList, values]
	);
	const mainProfileIds = useMemo(() => {
		return profileList.filter((e) => e.roleInPaper === 'main').map((e) => e.profileId);
	}, [profileList]);

	useEffect(() => {
		assignTimeResearchYear();
	}, []);

	const assignTimeResearchYear = () => {
		const descYear = props.timeResearchInformationList
			.sort((a, b) => b.year - a.year)
			.map((e) => e.year);
		const existedYear = descYear.some((e) => e === new Date().getFullYear());
		setTimeResearchYear(existedYear ? new Date().getFullYear() : descYear[0]);
	};

	return (
		<Dialog
			fullWidth
			maxWidth={isFullSize ? 'xl' : 'md'}
			open={inserting}
			onClose={() => setInserting(false)}
		>
			<DialogTitle>{`Thêm bài báo ${getTypeAsTitle(props.filter)}`}</DialogTitle>
			<DialogContent style={{ display: 'flex', overflowX: 'hidden' }}>
				<div
					style={{
						display: 'flex',
						paddingRight: 16,
						minWidth: isFullSize ? '50%' : '100%',
						maxWidth: isFullSize ? 960 : '100%'
					}}
				>
					<Grid container justifyContent="center" spacing={2} style={{ marginBottom: 10 }}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								select
								size="small"
								label="Phân loại"
								variant="outlined"
								value={props.type}
								SelectProps={{ native: true }}
								disabled
								// onChange={(event) => {
								// 	setType(event.target.value);
								// 	setFilter(TYPE_FILTER_MAPPING[event.target.value]);
								// }}
							>
								{scientificTypeSelections.map((e, index) => (
									<option key={index} value={e.type}>
										{e.title}
									</option>
								))}
							</TextField>
						</Grid>
						{props.filter === 'journalInternational' && <JournalInternational {...propsValues} />}
						{props.filter === 'journalDomestic' && <JournalDomestic {...propsValues} />}
						{props.filter === 'conference' && <Conference {...propsValues} />}
						{props.filter === 'conferenceDomestic' && <ConferenceDomestic {...propsValues} />}
						{props.filter === 'chapter' && <Chapter {...propsValues} />}
						{props.filter === 'workingPaper' && <WorkingPaper {...propsValues} />}
						{props.filter === 'other' && <Other {...propsValues} />}

						{props.filter !== 'workingPaper' && values?.purposeId === 5 && (
							<>
								<Grid item xs={3}>
									<TextField
										disabled
										select
										fullWidth
										size="small"
										value={timeResearchYear}
										SelectProps={{ native: true }}
										label="Năm học tính giờ nghiên cứu"
										variant="outlined"
										onChange={(event) => {
											setTimeResearchYear(parseInt(event.target.value));
											handleChangeForm('timeResearchDetailCode', null);
										}}
									>
										{yearList.map((e) => (
											<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
												{e} - {e + 1}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item xs={9}>
									<TextField
										select
										fullWidth
										size="small"
										label="Nhiệm vụ tính giờ nghiên cứu"
										variant="outlined"
										value={values?.timeResearchDetailCode || null}
										onChange={(event) =>
											handleChangeForm('timeResearchDetailCode', event.target.value)
										}
										InputLabelProps={{
											shrink: !(
												values?.timeResearchDetailCode === undefined ||
												values?.timeResearchDetailCode === null
											)
										}}
										error={!values?.timeResearchDetailCode}
									>
										{timeResearchDetailList.length > 0 ? (
											timeResearchDetailList?.map((e) => (
												<MenuItem
													key={e.code}
													value={e.code}
												>{`${e.description} - ${e.value}(h)`}</MenuItem>
											))
										) : (
											<MenuItem value={null} disabled>
												Chưa có dữ liệu
											</MenuItem>
										)}
									</TextField>
									{!values?.timeResearchDetailCode && (
										<FormHelperText error>Phải chọn một nhiệm vụ!</FormHelperText>
									)}
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Ghi chú"
								variant="outlined"
								value={values?.note || ''}
								multiline
								rows={3}
								onChange={(event) => handleChangeForm('note', event.target.value)}
							/>
						</Grid>

						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>
						<Grid item xs={2}>
							<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
								Thành viên
							</Typography>
						</Grid>
						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>

						<Grid item xs={12}>
							{profileList.map((e, index) => (
								<Grid key={`list-${index}`} container spacing={2}>
									<Grid
										item
										xs={3}
										style={{
											display: 'flex'
										}}
									>
										<FormControl required fullWidth error={!values?.profileIdForKpi}>
											<Button
												fullWidth
												color="primary"
												variant="outlined"
												style={{ height: 40 }}
												startIcon={e.isExternalTeacher ? <CheckBox /> : <CheckBoxOutlineBlank />}
												onClick={() => {
													handleChangeProfileList(index, 'isExternalTeacher', !e.isExternalTeacher);
													handleChangeProfileList(index, 'profileId', 0);
													handleChangeProfileList(index, 'name', null);
												}}
											>
												Ngoài trường
											</Button>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={
											props.filter === 'workingPaper' ||
											(values?.purposeId !== 1 && values?.purposeId !== 99999999)
												? 3
												: 3
										}
									>
										{e.isExternalTeacher ? (
											<TextField
												onChange={(event) => {
													handleChangeProfileList(index, 'name', event.target.value);
													handleChangeProfileList(index, 'profileId', 0);
													if (event.target.value) setHelperText(false);
												}}
												error={helperText && e.name === null}
												size="small"
												label="Thành viên"
												variant="outlined"
											/>
										) : (
											<Autocomplete
												disableClearable
												options={profileListFromProps}
												getOptionLabel={(option) => {
													if (option.code && option.name) {
														return `${option.code} - ${option.name}`;
													} else if (option.code) {
														return option.code;
													} else if (option.name) {
														return option.name;
													} else {
														return '';
													}
												}}
												value={
													profileListFromProps?.find((profile) => profile.id === e.profileId) ||
													profileListFromProps[0]
												}
												onChange={(event, profile) => {
													if (profileList[index]?.profileId === values?.profileIdForKpi)
														handleChangeForm('profileIdForKpi', null);
													handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
													handleChangeProfileList(index, 'name', profile?.name);
													if (parseInt(profile?.id) >= 0) setHelperText(false);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														error={helperText && e.profileId === null}
														size="small"
														label="Thành viên"
														variant="outlined"
													/>
												)}
											/>
										)}
										{helperText && e.profileId === null && (
											<FormHelperText style={{ color: 'red' }}>Không được để trống</FormHelperText>
										)}
									</Grid>
									<Grid
										item
										xs={
											props.filter === 'workingPaper' ||
											(values?.purposeId !== 1 && values?.purposeId !== 99999999)
												? 4
												: 2
										}
									>
										<FormControl fullWidth variant="outlined" size="small">
											<InputLabel
												id="roleInPaper-label"
												style={{ padding: '0 8px', backgroundColor: 'white' }}
											>
												Vai trò
											</InputLabel>
											<Select
												native
												fullWidth
												labelId="roleInPaper-label"
												value={e?.roleInPaper || 'main'}
												onChange={(event) =>
													handleChangeProfileList(index, 'roleInPaper', event.target.value)
												}
											>
												<option value="main">Tác giả chính</option>
												<option value="member">Đồng tác giả</option>
											</Select>
										</FormControl>
									</Grid>
									{values?.purposeId === 1 && (
										<Grid
											item
											xs={3}
											style={{
												display: props.filter === 'workingPaper' ? 'none' : 'flex'
											}}
										>
											<FormControl required fullWidth error={!values?.profileIdForKpi}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForKpi === 'number' &&
														values?.profileIdForKpi === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														handleChangeForm(
															'profileIdForKpi',
															values?.profileIdForKpi !== e.profileId ? parseInt(e.profileId) : null
														);
													}}
												>
													Tính KPI
												</Button>
												{!values?.profileIdForKpi && (
													<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									{values?.purposeId === 99999999 && (
										<Grid
											item
											xs={3}
											style={{
												display: props.filter === 'workingPaper' ? 'none' : 'flex'
											}}
										>
											<FormControl fullWidth error={!values?.profileIdForReward}>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={e.profileId === null}
													startIcon={
														typeof values?.profileIdForReward === 'number' &&
														values?.profileIdForReward === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														handleChangeForm(
															'profileIdForReward',
															values?.profileIdForReward !== e.profileId
																? parseInt(e.profileId)
																: null
														);
													}}
												>
													Tính thưởng
												</Button>
												{!values?.profileIdForReward && (
													<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									<Grid item xs={2}>
										<Button
											className="error-button"
											fullWidth
											color="secondary"
											variant="contained"
											style={{ height: 40 }}
											onClick={() => handleRemove(index)}
										>
											Xóa
										</Button>
									</Grid>
								</Grid>
							))}
						</Grid>

						<Grid item xs={12}>
							<Button fullWidth variant="outlined" onClick={handleInsert}>
								Thêm thành viên
							</Button>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						flexDirection: 'column',
						display: needCalKpi ? 'block' : 'none'
					}}
				>
					<EmploymentContractTable
						contract={detail}
						contractList={contractList}
						kpiIdForKpi={values?.kpiIdForKpi}
						contractIdForKpi={values?.contractIdForKpi}
						setDetail={setDetail}
						values={values}
						setValues={setValues}
						handleChangeForm={handleChangeForm}
					/>
					<EmploymentContractDetailTable contract={detail} kpi={detail} />
				</div>
				<div
					style={{
						flexDirection: 'column',
						display: needCalHour ? 'block' : 'none'
					}}
				>
					<ScientificResearchTimeTable
						profiles={profileList}
						mainProfileIds={mainProfileIds}
						totalHour={selectedTimeResearchDetail?.value ?? 0}
					/>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<Grid container justifyContent={'space-between'}>
					<div>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleOpenUploadModal}
							style={{ marginRight: 4 }}
						>
							Upload file minh chứng
						</Button>
					</div>
					<div style={{ float: 'right' }}>
						<Button
							variant="outlined"
							// color="secondary"
							onClick={() => handleClose()}
							style={{ marginRight: 4 }}
						>
							Huỷ
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={
								props.filter !== 'workingPaper' &&
								(!(values?.numberMainAuthor > 0) ||
									!(values?.numOfMember > 0) ||
									(!![
										'journalInternational',
										'journalDomestic',
										'conference',
										'conferenceDomestic'
									].find((e) => props.filter === e) &&
										(!(values?.submissionMonth > 0) || !(values?.acceptedMonth > 0))) ||
									(values?.purposeId === 5 && !values?.timeResearchDetailCode) ||
									(!values?.doi && files.length === 0))
							}
							onClick={() => handleSubmit()}
						>
							Xác nhận
						</Button>
					</div>
				</Grid>
			</DialogActions>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
				defaultFiles={files}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	fileUrl: select(state, 'fileReducer', 'fileUrl'),
	profile: select(state, 'profileReducer', 'detail'),
	profileList: select(state, 'profileReducer', 'list'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	isFetchingTimeResearchInformation: select(state, 'timeResearchInformationReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	pushNoti: (payload) => dispatch(notification(payload)),
	resetDetail: () => dispatch(resetDetail()),
	uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	insertScientificResearch: (payload) => dispatch(insertScientificResearch(payload)),
	insertScientificResearchOther: (payload) => dispatch(insertScientificResearchOther(payload)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id)),
	updateScientificResearch: (id, payload) => dispatch(updateScientificResearch(id, payload)),
	getTimeResearchInformation: (profileId, year) =>
		dispatch(getTimeResearchInformation(profileId, year))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(SolutionInsertModal));
