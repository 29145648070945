import React, { useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid
} from '@material-ui/core';

import KPTextfield from '../KPTextfield';

function KPTextform(props) {
	const { values, setValues } = props;
	const [open, setOpen] = useState(false);
	const [eventToRevert, setEventToRevert] = useState(null);

	const handleChangeForm = (key, val, type) => {
		if (props.handleChangeForm) {
			props.handleChangeForm(key, val, type);
		} else {
			switch (type) {
				case 'file':
					if (val?.[0]) setValues({ ...values, [key]: val[0] });
					break;
				default:
					setValues({ ...values, [key]: val });
					break;
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		props.handleSubmit(event);
	};

	const handleRevert = (event) => {
		event.preventDefault();
		setEventToRevert(event);
		setOpen(true);
	};

	const handleConfirmRevert = () => {
		if (eventToRevert) {
			props.handleRevert(eventToRevert);
		}
		setOpen(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Box
				id={props.id}
				component={props.component || 'form'}
				onSubmit={handleSubmit}
				onReset={handleRevert}
			>
				<Grid container spacing={2} justifyContent="center">
					{props.textForm.map((elm) => (
						<Grid item key={elm.key} xs={elm.xs}>
							<KPTextfield
								id={elm.key}
								value={values?.[elm.key]}
								handleChangeForm={handleChangeForm}
								{...elm}
							/>
						</Grid>
					))}
				</Grid>
			</Box>

			{/* Confirmation dialog for reverting student research status */}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Xác nhận lựa chọn</DialogTitle>
				<DialogContent>
					Bạn có chắc muốn thay đổi đề tài quay lại về trạng thái chưa gửi báo cáo tiến độ!
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined">
						Huỷ
					</Button>
					<Button onClick={handleConfirmRevert} color="primary" variant="contained">
						Xác nhận
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default KPTextform;
